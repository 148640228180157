import React from 'react'
import { SecondaryButton } from './Button/Button'

export default function VibeCheckButton({
  onContactUser, author,
}) {

  const link = author.attributes.profile.publicData.calendlyLink
  return (
    <div style={{marginBottom: 16}}>
      <SecondaryButton onClick={link ? () => open(link) : onContactUser}>
        Let&apos;s chat
      </SecondaryButton>
    </div>
  );
}
